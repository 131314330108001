import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/Layout"
import { StaticImage } from "gatsby-plugin-image"
import AllFestivalFilms from "../../components/AllFestivalFilms"
import "../../assets/css/global.css"
import Seo from "../../components/Seo"
import { Helmet } from 'react-helmet'

const monthlyFestival = () => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>Monthly Festival</title>
        <link rel="canonical" href="https://filmchronicle.com/watch/monthly-festival" />
      </Helmet>
      <Seo title="Monthly Festival" description="Upvote your favorite films this month!" image="../../../assets/images/Watch-Films__Monthly-Festival-Hero.png" />
      <header className="hero">
        <StaticImage
          src="../../assets/images/Watch-Films__Monthly-Festival-Hero.png"
          alt="flour and eggs"
          className="hero-img"
          placeholder="tracedSvg"
          layout="fullWidth"
        ></StaticImage>
        <div className="hero-container">
          <div className="hero-text">
            <h1>Monthly Festival</h1>
          </div>
        </div>
      </header>
      <div className="container-lg">
        <div className="special-section grid-cols-1 pb-50">
            <div>
                <h4 style={{marginBottom: "1rem"}}>How it works?</h4>
                <p style={{fontWeight: "300", marginBottom: "2rem"}}>Watch submitted films, upvote favorites, our judges will take the top six &amp; choose a monthly winner to be featured.</p>
            </div>
            {/* <div>
                <h4 style={{marginBottom: "1rem"}}>Maintenance update!</h4>
                <p style={{fontWeight: "300", marginBottom: "2rem"}}>Maintenance/update is ongoing. Monthly festival will be back in October 1st. Free submission is open <Link to="/submissions/monthly-festival">here.</Link></p>
            </div> */}
            <div>
              <h2 style={{fontSize: "30px"}}>Film Submissions</h2>
              <p style={{fontSize: "20px"}}>Browse the submitted films below!</p>
            </div>
        </div>
      </div>
      <div><AllFestivalFilms genres={""}/></div>
    </Layout>
  )
}

export default monthlyFestival
