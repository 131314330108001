import React, {useState, useEffect, useRef} from "react"
import FilmsList from "./FilmsList"
import { graphql, useStaticQuery } from "gatsby"
import "../assets/css/global.css"
import LoadMore from "../assets/images/Load-more-arrow.svg"

const query = graphql`
  {
    allDatoCmsVideo(filter: {activeInFestival: {eq: true}}) {
      nodes {
        id
        title
        videoUrl {
          providerUid
        }
        genre
        universityCollege
        length
      }
    }
  }
`

const AllFestivalFilms = (genre) => {
  const data = useStaticQuery(query)
  const allFilms = data.allDatoCmsVideo.nodes

  // State for the list
  const [list, setList] = useState([...allFilms.slice(0..allFilms).sort(function(a, b){return 0.5 - Math.random()})])

  // State to trigger oad more
  const [loadMore, setLoadMore] = useState(false)

  // State of whether there is more to load
  const [hasMore, setHasMore] = useState(allFilms.length > 4)

  //Set a ref for the loading div
  const loadRef = useRef()

  // Handle intersection with load more div
  const handleObserver = (entities) => {
    const target = entities[0]
    if (target.isIntersecting) {
      setLoadMore(true)
    }
  }

  //Initialize the intersection observer API
  useEffect(() => {
    var options = {
      root: null,
      rootMargin: "20px",
      threshold: 1.0,
    }
    const observer = new IntersectionObserver(handleObserver, options)
    if (loadRef.current) {
      observer.observe(loadRef.current)
    }
  }, [])

  // Handle loading more articles
  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length
      const isMore = currentLength < allFilms.length
      const nextResults = isMore
        ? allFilms.slice(currentLength, currentLength + 4)
        : []
      setList([...list, ...nextResults])
      setLoadMore(false)
    }
  }, [loadMore, hasMore]) //eslint-disable-line

  //Check if there is more
  useEffect(() => {
    const isMore = list.length < allFilms.length
    setHasMore(isMore)
  }, [list]) //eslint-disable-line

  return (
    <section className="container-lg">
      <FilmsList id="top" films={list} genres={genre} />
      <div style={{paddingTop:  "5rem"}} ref={loadRef}>
        {hasMore ? (
            <p style={{textAlign: "center", marginBottom: "2rem"}}>No films found...</p>
          ) : (
            <div className="grid-cols-3">
                <p className="col-span-3" style={{textAlign: "center", marginBottom: "4rem"}}>No more results</p>
                <a style={{gridColumnStart: "6"}} href="#top">
                <img
                  src={LoadMore}
                  alt="back to top arrow"
                  placeholder="tracedSVG"
                  layout="constrained"
              />
                </a>
            </div>
          )}
      </div>
    </section>
  )
}

export default AllFestivalFilms